export default {
  home: {
    minute: 'Minute',
    price_info: 'Genießen Sie die kostenlose Nutzung für {mianfei} Minuten. Danach werden Ihnen für jeden weiteren Zeitraum {currencyName}{jifei} berechnet. Die maximale Tagesgebühr beträgt {currencyName}{fengding}. Wenn Sie die Powerbank nicht nach {pOvertimeDays} Stunden zurückgeben, wird eine Überstundengebühr von {currencyName}{yajin} erhoben und sie geht in Ihr Eigentum über.',
    feature_1: 'Passt auf jedes Telefon, alle Ladekabel sind im Lieferumfang der Powerbank enthalten.',
    feature_2: 'Sie können sie überall zurückgeben, schauen Sie einfach auf die Karte.',
    feature_3: 'Spezielles Design - kabelloses Aufladen (nur für unterstützte Module)',
    tips_1: 'Deine Kaution von {currencyName}{yajin} wird dir nach der Rückgabe des Powerbanks erstattet.',
    tips_2: 'Kaufe eine Kaution von {currencyName}{yajin}, um zu mieten. Verbrauchte Kaution kann erstattet werden.',
    tips_3: 'Das aktuelle Guthaben beträgt {currencyName}{myyue} und kann direkt vermietet werden.',
    submit: 'Miete starten',
    borrow: 'Mieten',
    msg_fail_title: 'Fehler',
    msg_fail_content: 'Miete fehlgeschlagen, bitte versuche es erneut.',
    msg_rentcount: 'Du hast derzeit {zujieNum} offene Bestellungen.',
    goborrow: 'Los geht\'s mit dem Aufladen!',
    nearby: 'In der Nähe befindliche Stationen anzeigen',
    preauth: 'Jetzt laden'
  },
  login: {
    code_title: 'SMS-Bestätigung',
    phone_number: 'Telefonnummer',
    verification_code: 'Bitte gib den SMS-Bestätigungscode ein',
    captcha_code: 'Code',
    send_code: 'Code senden',
    login: 'Anmelden',
    agree: 'Durch Klicken auf Anmelden stimmst du den ',
    readme: `Nutzungsbedingungen`,
    msg_fail_login: 'Anmeldung fehlgeschlagen',
    msg_success_login: 'Anmeldung erfolgreich',
    msg_fail_user: 'Fehler beim Abrufen der Benutzerinformationen. Möchtest du es erneut versuchen?',
    sign_in_with: 'Anmelden mit',
    no_sms: 'Keine SMS erhalten',
    retry: 'Erneut senden'
  },
  confirm: {
    title: 'Zahlungsbestätigung',
    paynow: 'Jetzt bezahlen',
    SMSConfirm: 'Bitte zahle gemäß der auf deinem Telefon erhaltenen SMS und klicke dann auf die Schaltfläche "Bestätigen", um fortzufahren.',
    pay_label: 'Betrag',
    auth_label: 'Vorautorisierung',
    price_tips: 'Dein Guthaben kann auf Anfrage zurückgezogen werden. Es kann 1-5 Werktage dauern, bis es auf deinem Zahlungskonto angezeigt wird.',
    auth_tips: 'Eine Vorautorisierung in Höhe von {0}{1} ist obligatorisch. Der tatsächliche Mietbetrag wird bei Rückgabe des Powerbanks abgezogen.',
    pay_type: 'Bitte wähle deine bevorzugte Zahlungsmethode',
    submit: 'Zahlung bestätigen',
    msg_loading: 'Laden...',
    msg_fail_title: 'Warnung',
    msg_fail_payment: 'Fehler beim Auslösen der Kautionzahlung',
    msg_fail_order: 'Fehler beim Erstellen der Mietbestellung',
    msg_fail_support: 'Das System wird nicht unterstützt',
    msg_fail_confirm: 'Bestätigen',
    msg_fail_retry: 'Erneut versuchen',
    msg_fail_cancel: 'Abbrechen',
    noPhone: "Bitte Telefonnummer eingeben"
  },
  mine: {
    title: 'Benutzerprofil',
    wallet: 'Mein Geldbeutel',
    balance: 'Mein Guthaben',
    deposit: 'Kaution',
    wallet_in: 'Aufladen',
    wallet_out: 'Auszahlung',
    wallet_log: 'Geldbeutel-Protokoll',
    order: 'Bestellung',
    coupon: 'Gutschein',
    login_tips: 'Bitte anmelden',
    msg_fail_title: 'Fehler',
    msg_fail_update: 'Änderung fehlgeschlagen, bitte versuche es erneut',
    msg_loading: 'Laden...',
    msg_fail_user: 'Fehler beim Abrufen der Benutzerinformationen. Möchtest du es erneut versuchen?',
    help: 'Hilfe',
    setting: 'Einstellungen',
    logout: 'Abmelden',
    buy: 'Ausrüstung kaufen',
    payment: 'Zahlung'
  },
  msg: {
    deletecard: 'Bist du dir sicher, dass du die Karte entfernen möchtest?',
    no_phone: 'Telefonnummer erforderlich',
    loading: 'Laden...',
    title: 'Hinweis',
    success: 'Erfolgreiche Operation',
    error: 'Unbekannter Fehler, bitte versuche es später erneut.',
    roleerror: 'Unzureichende Berechtigungen, bitte konsultiere den Administrator',
    neterror: 'Netzwerkverbindung fehlgeschlagen. Bitte versuche es später erneut',
    confirm: 'Bestätigen',
    download: "Herunterladen",
    retry: 'Erneut versuchen',
    cancel: 'Abbrechen',
    title_fail: 'Warnung',
    vieworder: 'Bestellung anzeigen',
    success_payment: 'Zahlung abgeschlossen',
    success_buy: 'Kauf abgeschlossen',
    fail_payment: 'Zahlungsfehler',
    keyword: 'Schlüsselwort',
    ispayorder: 'Bitte bestätige, ob die Zahlung erfolgt ist?',
    unpay: 'Unbezahlt',
    yespay: 'Bezahlt',
    notopen: 'Noch nicht geöffnet',
    appoffline: 'Die App wurde noch nicht gestartet, bitte warte'
  },
  result: {
    title: 'Bestätigen',
    tips: 'Deine Powerbank ist bereit, schnapp sie dir und genieße deinen stressfreien Tag!',
    gotit: 'Verstanden',
    price_plan: 'Tarif',
    msg_success: 'Erfolgreich gemietet',
    msg_tips_loading: 'Die Powerbank wird aktiviert, bitte habe etwas Geduld...',
    msg_result_loading: 'Die Zahlung wird bestätigt...',
    msg_fail_confirm: 'Bestätigen',
    btn_usage: 'Hinweis zur Verwendung',
    btn_return: 'Rückgabehinweis',
    deposit: 'Kaution',
    msg_fail_content: 'Anfrage fehlgeschlagen, bitte versuche es erneut',
    price_label: 'pro Stunde',
    price_info: 'Die ersten {mianfei} Minuten sind kostenlos, maximal wird pro Tag {ccurrency}{fengding} berechnet. Die Kaution wird automatisch abgezogen, wenn die Miete {ccurrency}{yajin} erreicht.',
    tips_1: 'Bitte hole den Powerbank gemäß der Abbildung <span class="number">Nr.{number}</span> ab.',
    tips_2: 'Bitte lege die Kabel ordentlich in die vorgesehenen Öffnungen und stecke die Powerbank mit dem <span class="highlight">[Pfeil nach unten]</span> in die Öffnung zurück, wenn du sie zurückgibst.',
    msg_note_usage: 'Der Powerbank ist mit 3 Arten von Kabeln und kabellosem Laden ausgestattet, bitte verwende sie nach Bedarf.',
    msg_note_return: 'Bitte lege die Kabel ordentlich zusammen und stecke die Powerbank mit dem <span class="highlight">[Pfeil nach unten]</span> in die Öffnung zurück, wenn du sie zurückgibst.',
    return_info: 'Beim Zurückgeben die Kabel ordentlich zusammenlegen und die Seite mit den Metallchips in eine leere Öffnung stecken, bis sie klickt.'
  },
  result_msg: {
    loading: 'Laden...',
    refresh: 'Aktualisieren'
  },
  order: {
    title: 'Mietbestellung',
    in_use: 'In Benutzung',
    ended: 'Beendet',
    msg_error: 'Anfrage fehlgeschlagen'
  },
  order_item: {
    order_number: 'No.',
    venue_rented: 'Abholort',
    time_rented: 'Geliehene Zeit',
    venue_returned: 'Rückgabeort',
    time_returned: 'Rückgabezeit',
    charge_standard: 'Tarif',
    charge_standard_info: '{pJifei} {pCurrency} pro Stunde (erste {pMian} Minuten kostenlos)',
    rent_occurred: 'Mietbetrag',
    returned: 'Zurückgegeben',
    unreturned: 'Nicht zurückgegeben',
    type_0: 'Nicht abgeschlossen',
    type_1: 'In Miete',
    type_2: 'Abgebrochen',
    type_3: 'Zurückgegeben',
    type_4: 'Fehler aufgetreten',
    type_5: 'Überfällig',
    min: 'Min',
    payment: 'Zahlung'
  },
  setting: {
    title: 'Einstellungen',
    lang: 'Sprache'
  },
  map: {
    title: 'In der Nähe befindliche Stationen',
    result_msg: 'Keine Station in der Nähe gefunden',
    msg_title: 'Fehler',
    no_support: 'Entschuldigung! Dein Browser unterstützt die Standortbestimmung nicht.',
    my_position: 'Mein Standort',
    distance: 'Entfernung:',
    shop_time: 'Stunden',
    free: 'Verfügbar',
    return: 'Freie Steckplätze',
    shop_title: 'Händlerdetails',
    address: 'Adresse',
    go_here: 'Hierhin gehen',
    '在线': 'Online',
    '离线': 'Offline'
  },
  wallet_card: {
    title: 'Bezahlmethode hinzufügen',
    addcard: 'Kreditkarte hinzufügen',
    newcard: 'Neue Karte hinzufügen',
    paypal: 'Paypal hinzufügen',
    savenow: 'Speichern',
    savecard: 'Alle gespeicherten Methoden',
    savedic: 'Bitte Methode hinzufügen',
    msg_fail_title: 'Warnung',
    msg_fail_order: 'Miete fehlgeschlagen',
    msg_fail_confirm: 'Bestätigen',
    msg_fail_retry: 'Erneut versuchen',
    msg_fail_cancel: 'Abbrechen',
    delete: 'Entfernen'
  },
  scanQr: {
    NotAllowedError: 'You need to grant camera access',
    NotFoundError: 'There is no camera on this device',
    NotSupportedError: 'Security context required (HTTPS, localhost)',
    NotReadableError: 'The camera is in use',
    OverconstrainedError: 'Installing the camera is not appropriate',
    StreamApiNotSupportedError: 'This browser does not support the streaming API'
  }
}
