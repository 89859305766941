<template>
  <!-- <page :title="i18n.title"> -->
  <div class="home">
    <div class="google-map">
      <google-map
        :locationList="list"
        :latLng="latLng"
        @center="onCenter"
        @zoom="onZoom"
        @setItem="onSetItem"
        @onmap="show = false"
      />
      <div class="home-left">
        <van-image
          :src="require('@/assets/imgs/icon_More@2x.png')"
          width="1rem"
          @click="isMenuView = true"
        ></van-image>
      </div>
      <!-- <div class="home-right">
      <van-image :src="require('@/assets/imgs/icon_messages@2x.png')" width="1rem" @click="onClick"></van-image>
    </div> -->
      <!-- <div class="home-message">
      <div class="home-m-box">
        <van-image :src="require('assets/imgs/powerIn.png')" width="1.3rem"></van-image>
        <div class="m-box-title">You have orders in progress.</div>
        <div class="m-box-method">
          <span style="margin-right: 5px;">Method of return</span>
          <van-icon name="arrow"></van-icon>
        </div>
      </div>
    </div> -->
      <div class="home-bottom">
        <!-- <div class="home-scan flex-s">
        <div class="home-scan-aside" @click='onClick'>
           <img :src="require('@/assets/imgs/icon_search@2x.png')">
           <span>search</span>
        </div>
        <div class="home-scan-aside" @click="onClick">
           <img :src="require('@/assets/imgs/icon_help.png')">
           <span>help</span>
        </div>
      </div> -->
        <div class="scan-box">
          <div class="flex-c">
            <van-image
              :src="require('@/assets/imgs/icon_Scan@2x.png')"
              @click="scanQr"
              width="2.2rem"
            ></van-image>
          </div>
        </div>
      </div>
      <transition name="fade">
        <div v-if="item && show" class="shop-info">
          <div class="shop-info-head">
            <div class="shop-title">
              <h3>{{ item.shopName }}</h3>
              <span
                :class="[
                  item.infoStatus === '在线' ? 'shop-tag-active' : '',
                  'shop-tag'
                ]"
                >{{ $t(`map.${item.infoStatus}`) }}</span
              >
            </div>
            <a @click="goDetail"
              ><img src="@/assets/imgs/popup_btn_navigation.png" alt=""
            /></a>
          </div>
          <div class="shop-info-content">
            <div class="shop-logo">
              <img v-if="item.shopBanner" :src="item.shopBanner" alt="" />
              <img v-else src="../../assets/imgs/banner-home.jpg" alt="" />
            </div>
            <div class="shop-detail">
              <div class="shop-time">
                <span class="time">
                  <img src="../../assets/imgs/map.png" alt="" />
                  {{ item.shopAddress1 }}
                </span>
                <span class="time">
                  <img src="../../assets/imgs/distance.png" alt="" />
                  {{ item.distance }}
                </span>
                <span class="time">
                  <img src="../../assets/imgs/icon_time.png" alt="" />
                  {{ item.shopTime }}
                </span>
              </div>
              <div class="shop-op">
                <span class="free-num"
                  >{{ i18n.free }}：{{ item.freeNum }}</span
                >
                <span class="return-num"
                  >{{ i18n.return }}：{{
                    Number(item.batteryNum) - Number(item.freeNum)
                  }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <popup-warp
      v-model="isMenuView"
      dialogTransition="slide-left"
      style="background: #fff; height: 100vh"
    >
      <menu-side></menu-side>
    </popup-warp>
    <popup-warp
      v-model="ishomeSearch"
      position="bottom"
      class="search"
      style="border-radius: 60px 60px 0px 0px"
    >
      <div class="pop-box">
        <searchPlus v-model="searchText" @handleValue="reload"></searchPlus>
      </div>
    </popup-warp>
    <div class="fixed" v-if="isPickUpShow" @click="isPickUpShow = false">
      <ScanQr @scanQr="onScanQr" />
    </div>
  </div>
  <!-- </page> -->
</template>

<script>
import { mapState } from "vuex"
import GoogleMap from "@/views/map/google-map.vue"
import ScanQr from "@/views/scanQr/scanQr.vue"
import { debounce } from "../../utils/index"
import { SHOP_LIST, QR_CODE } from "../../apis/cabinet"
import { PopupWarp } from "@/components/popup"
import { MenuSide } from "@/components/menu"
import searchPlus from "@/components/searchPlus/searchPlus.vue"
export default {
  components: {
    GoogleMap,
    PopupWarp,
    searchPlus,
    MenuSide,
    ScanQr
  },
  data() {
    return {
      latLng: [49.79130482024872, 9.9533496158753],
      zoomLevel: "4",
      list: [],
      item: null,
      show: false,
      isMenuView: false,
      ishomeSearch: false,
      isPickUpShow: false,
      searchText: ""
    }
  },
  computed: {
    ...mapState(["user", "token", "browser", "qrcode", "cabinet"]),
    i18n() {
      return this.$t("map")
    },
    i18nHome() {
      return this.$t("home")
    },
    i18nMsg() {
      return this.$t("msg")
    }
  },
  watch: {
    latLng() {
      this.lazyLoad(this)
    }
  },
  methods: {
    onClick() {
      console.log("未开放")
      this.$toast(this.i18nMsg.notopen)
    },
    reload(val) {
      console.log("reload===", val)
    },
    scanQr() {
      if (this.user && this.token) {
        this.isPickUpShow = true
      } else {
        this.$router.push("/login")
      }
    },
    onScanQr(qr) {
      console.log("qr====", qr)
      this.isPickUpShow = false
      let arr = qr.split("=")
      this.onScan(arr[1])
    },
    onScan(qrcode) {
      this.$loading(true)
      this.$post(
        `${QR_CODE}?id=${qrcode}`,
        {},
        (resp) => {
          this.$loading(false)
          this.$store.commit("cabinet", {
            qrcode: this.code,
            ...resp
          })
          if (resp.zujieNum && resp.zujieNum > 0 && !resp.haveMifiBattery) {
            this.$confirm({
              title: this.i18nHome.msg_fail_title,
              content: `${this.i18nHome.msg_rentcount}`.format(resp),
              confirmText: this.i18nMsg.vieworder,
              cancelText: this.i18nMsg.cancel
            })
              .then(() => {
                this.$router.push("/order")
                this.$store.commit("qrcode", "")
              })
              .catch(() => {
                this.$store.commit("qrcode", "")
              })
          } else {
            this.$router.push("/home?cabinetshow=true")
            // this.isScan = true
            // this.isBtns = false
            // this.adVisible = true
          }
        },
        (error) => {
          this.$loading(false)
          this.$toast(error)
        }
      )
    },
    getNearbyShop() {
      this.$loading(true)
      let formdata = new FormData()
      formdata.append("coordType", "WGS－84")
      formdata.append("mapType", "WGS－84")
      formdata.append("lat", this.latLng[0])
      formdata.append("lng", this.latLng[1])
      formdata.append("zoomLevel", this.zoomLevel)
      this.$post(
        SHOP_LIST,
        formdata,
        (resp) => {
          this.$loading(false)
          this.list = resp.list
        },
        (error) => {
          this.$loading(false)
          this.$toast(error)
        },
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      )
    },
    onZoom(zoomLevel) {
      // console.log('zoom', zoomLevel)
      this.zoomLevel = zoomLevel
      this.lazyLoad(this)
    },
    lazyLoad: debounce((vm) => {
      vm.getNearbyShop()
    }, 500),
    onCenter(latLng) {
      this.latLng = latLng
      this.lazyLoad(this)
    },
    onSetItem(val) {
      this.item = val
      this.show = true
    },
    goDetail() {
      console.log("show detail")
      this.$router.push({ path: "nearby_shop", query: this.item })
    },
    checkQrcodeDevice() {
      const qrcode = sessionStorage.getItem("qrcode_cabinetId")
      if (this.cabinet && qrcode === this.cabinet.qrcode) {
        this.onScan(qrcode)
      }
    }
  },
  created() {
    const { qrcode, latitude, longitude } = this.$route.query
    if (qrcode) this.onScan(qrcode)
    // let latitude = this.$route.query.latitude
    // let longitude = this.$route.query.longitude
    if (latitude && longitude) {
      this.latLng = [+latitude, +longitude]
    }
    this.checkQrcodeDevice()
  },
  mounted() {
    // this.getNearbyShop()
  }
}
</script>

<style lang="less" scoped>
.search {
  /deep/.popup-warp {
    border-radius: 60px 60px 0px 0px;
  }
  .pop-box {
    // width: 100%;
    height: 1061px;
    background: #f5f5f5;
    box-shadow: 0px -10px 20px 1px rgba(0, 0, 0, 0.1);
    border-radius: 60px 60px 0px 0px;
    padding: 50px 40px;
  }
}
.fixed {
  position: fixed;
  bottom: 0;
  width: 100%;
  // background: rgba(45, 45, 45, 0.5);
  height: 100%;
}
.home {
  position: relative;
  display: flex;
  .home-left {
    position: fixed;
    top: 96px;
    left: 40px;
    z-index: 1;
  }
  .home-right {
    position: fixed;
    top: 96px;
    right: 40px;
    z-index: 1;
  }
  .van-image /deep/ .van-image__img {
    width: 100%;
  }
  .home-message {
    width: 100%;

    position: fixed;
    top: 250px;
    margin: 0 auto;
    z-index: 1;
    .home-m-box {
      width: 90%;
      margin: 0 auto;
      height: 113px;
      background: #b2e6c7;
      border-radius: 18px 18px 18px 18px;
      display: flex;
      justify-content: start;
      position: relative;
      .m-box-title {
        font-size: 28px;
        font-family: Montserrat-SemiBold;
        color: #00ab46;
        line-height: 2;
      }
      .m-box-method {
        position: absolute;
        bottom: 10px;
        right: 10px;
      }
    }
  }
  .home-bottom {
    position: fixed;
    bottom: 75px;
    z-index: 1;
    width: 100%;
    .shop {
      float: right;
      margin-right: 40px;
      margin-top: -200px;
    }
    .home-scan {
      width: 570px;
      background: #000000;
      border-radius: 120px 120px 120px 120px;
      margin: 0 auto;
      display: flex;
      position: relative;
      padding: 10px 0;
      &-aside {
        width: 70px;
        z-index: 9;
        margin: 0 50px;
        color: #fff;
        text-align: center;
        img {
          width: 100%;
        }
      }
      .scan-box {
        position: absolute;
        width: 100%;
        .scan-btn {
          width: 170px;
          height: 170px;
          border-radius: 50%;
          background: #ffffff;
          box-shadow: 0px -3px 10px 1px rgba(26, 34, 41, 0.2);
        }
        .scan-label {
          font-size: 30px;
          font-family: Montserrat-SemiBold;
          font-weight: bold;
          color: #ffffff;
          text-align: center;
          margin-top: 10px;
        }
      }
    }
  }
  .shop {
    justify-content: end;
  }
}
.google-map {
  width: 100%;
  height: 100%;
  position: fixed;
  bottom: 0;
  overflow: hidden;
}
.shop-info {
  position: fixed;
  bottom: 0;
  z-index: 100;
  // height: 350px;
  width: 100%;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  background: #fff;
  padding-bottom: 30px;
  .shop-info-head {
    // width: 100%;
    display: flex;
    padding: 20px 20px 0 20px;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    .shop-title {
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      align-items: center;
      h3 {
        padding: 0;
        margin: 0;
      }
      .shop-tag {
        margin-left: 20px;
        font-size: 24px;
        padding: 5px 15px;
        border: 1px solid #cacdd1;
        border-radius: 10px;
      }
      .shop-tag-active {
        color: #8dd35f;
        border: 1px solid #8dd35f;
      }
    }
    img {
      width: 60px;
      height: 60px;
    }
  }
  .shop-info-content {
    display: flex;
    height: calc(100% - 49px);
    padding: 0px 20px;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    .shop-logo {
      width: 200px;
      img {
        max-height: 180px;
        max-width: 200px;
      }
    }
    .shop-detail {
      height: 100%;
      margin-left: 14px;
      p {
        margin: 0;
        font-weight: 600;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        text-overflow: ellipsis;
        overflow: hidden;
        word-wrap: break-word;
        word-break: break-all;
      }
      .shop-time {
        margin: 15px 0;
        font-size: 22px;
        line-height: 30px;
        .time {
          padding: 4px 0;
          position: relative;
          padding-left: 10px;
          display: flex;
          flex-direction: row;
          align-items: center;
          img {
            display: inline-block;
            width: 30px;
            height: 30px;
            margin-right: 8px;
          }
        }
        // .time::before {
        //   content: '';
        //   position: absolute;
        //   height: 25px;
        //   width: 2px;
        //   top: 10px;
        //   left: 0;
        //   background: #23a8f2;
        // }
      }
      .shop-op {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        align-items: center;
        .return-num {
          margin-left: 20px;
          padding: 5px 10px;
          border: 1px solid #23aaf2;
          border-radius: 10px;
          color: #23aaf2;
        }
        .free-num {
          padding: 5px 10px;
          border: 1px solid #8dd35f;
          border-radius: 10px;
          color: #8dd35f;
        }
      }
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: height 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  height: 0;
}
.detailfade-enter-active,
.detailfade-leave-active {
  transition: opacity 0.5s;
}
.detailfade-enter, .detailfade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.mask {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: rgba(#000, 0.5);
  z-index: 10000;
  overflow: hidden;
  .shop-content {
    height: 600px;
    width: 100%;
    position: absolute;
    z-index: 100;
    bottom: 0;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    box-shadow: 0 -5px 10px #9e9b9b;
    background: #fff;
    .shop-title {
      display: flex;
      flex-direction: column;
      padding: 20px;
      background: #fff;
      border-radius: 20px;
      .title-info {
        padding: 0 30px;
        p {
          margin: 0 0 10px 0;
        }
        .return-num {
          margin-left: 20px;
          padding: 5px 10px;
          border: 1px solid #23aaf2;
          border-radius: 10px;
          color: #23aaf2;
        }
        .free-num {
          padding: 5px 10px;
          border: 1px solid #8dd35f;
          border-radius: 10px;
          color: #8dd35f;
        }
        .shop-time {
          h4 {
            margin: 20px 0;
            padding: 10px 0;
            border-bottom: 1px solid #cacdd1;
          }
        }
      }
      .shop-title-head {
        display: flex;
        flex-direction: row;
        align-items: center;
        h2 {
          margin: 10px 30px;
          padding: 20px 0;
          border-bottom: 1px solid #cacdd1;
        }
        .shop-tag {
          margin-left: 20px;
          font-size: 24px;
          padding: 5px 15px;
          border: 1px solid #cacdd1;
          border-radius: 10px;
        }
        .shop-tag-active {
          color: #8dd35f;
          border: 1px solid #8dd35f;
        }
      }
    }
    .address-detail {
      p {
        margin: 0;
        text-align: left;
        padding: 0 30px;
      }
    }
  }
}
</style>>
